<template>
    <div class="footer">
        <div class="footer_goodFaith">
            <div class="goodFaith Between">
                <div class="list flex-center" v-for="(item, index) in goodFaith" :key="index">
                    <img :src="item.icon_img" alt="" />
                    <div class="name">{{ item.name }}</div>
                </div>
            </div>
            <div class="privacy_agreement flex">
                <div class="footer_fl flex">
                    <div class="footer_fl_title" v-for="(item, index) in serveList" :key="index">
                        <div class="title nowrap">{{ item.label }}</div>
                        <div class="footer_fl_list" v-for="(items, indexs) in item.children" :key="indexs">
                            <div class="list pointer" @click="serveBtn(items)">
                                {{ items.label }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer_content">
                    <img class="footer_logo" :src="
                        platformInfo ? platformInfo.iconUrl : '../assets/img/logo2.png'
                    " alt="" />
                    <div class="workingTtime">
                        工作时间：{{ platformInfo.operatingHours }}
                    </div>
                    <div class="contactPhone flex-center">
                        <img src="../assets/img/phone2.png" alt="" />
                        <div class="phone">{{ platformInfo.telephone }}</div>
                    </div>
                </div>
                <div class="footer_fr">
                    <div class="scan_code display column">
                        <img :src="
                            platformInfo ? platformInfo.qrCode : '../assets/img/code.png'
                        " alt="" />
                        <div class="scan_code_phone_buy">扫码手机购</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_for_record">
            <div class="footer_for_record_main flex-center column">
                <div class="classification flex pointer">
                    <div class="classification_list flex" @click="jump(item.url)" v-for="(item, index) in classification"
                        :key="index">
                        {{ item.name }}
                        <div class="line" v-if="item.name != '果尔佳服务'"></div>
                    </div>
                </div>
                <div class="rights_reserved flex-center pointer" @click="recordBtn">
                    Copyright 2022 - 2026 Zhushou Network Co., Ltd. 筑手网络有限公司
                    版权所有 粤ICP备20000797号-2.
                </div>
                <div class="blogroll display flex-wrap">
                    友情链接：
                    <div class="blogroll_list pointer" v-for="(item, index) in blogroll" :key="index"
                        @click="toWebsite(item.link)">
                        {{ item.title }}
                    </div>
                </div>
                <div class="copyright_img display">
                    <div class="list" v-for="(item, index) in copyright" :key="index">
                        <img :src="item.icon_img" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            goodFaith: [
                {
                    icon_img: require('../assets/img/zp.png'),
                    name: '正品保障',
                },
                {
                    icon_img: require('../assets/img/kf.png'),
                    name: '专属客服',
                },
                {
                    icon_img: require('../assets/img/hq.png'),
                    name: '货期保证',
                },
                {
                    icon_img: require('../assets/img/fp.png'),
                    name: '发票保障',
                },
                {
                    icon_img: require('../assets/img/cx.png'),
                    name: '诚信服务',
                },
            ],
            classification: [
                {
                    id: 1,
                    name: '筑手建筑防护超市',
                    url: '/homes/index',
                },
                {
                    id: 2,
                    name: '建筑场景',
                    url: '/scene/newScene',
                },
                {
                    id: 3,
                    name: '修缮场景',
                    url: '/scene/oldScene',
                },
                {
                    id: 4,
                    name: '设计与施工技术',
                    url: '/build/index',
                },
                {
                    id: 5,
                    name: '果尔佳服务',
                    url: '/serviceSystem/home',
                },
            ],
            copyright: [
                {
                    icon_img: require('../assets/img/copyright1.png'),
                },
                {
                    icon_img: require('../assets/img/copyright2.png'),
                },
                {
                    icon_img: require('../assets/img/copyright3.png'),
                },
                {
                    icon_img: require('../assets/img/copyright4.png'),
                },
                {
                    icon_img: require('../assets/img/copyright5.png'),
                },
            ],
            blogroll: [],
            // 平台信息
            platformInfo: {},
            serveList: [],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // 查询友情链接
            this.api.queryByLists().then((res) => {
                let data = res.data
                let blogroll = []
                data.forEach((item, index) => {
                    if (item.status != 0) {
                        blogroll.push(item)
                    }
                })
                this.blogroll = blogroll
            })
            // 查询平台信息
            this.api.selectInfo().then((res) => {
                this.platformInfo = res.data || {}
                this.$store.commit('getPlatformInfo', res.data)
            })
            this.api.findIServerType().then((res) => {
                this.serveList = res.data || []
            })
        },
        // 跳转友情链接
        toWebsite(url) {
            window.open(url, '_blank')
        },
        serveBtn(item) {
            let routeData = this.$router.resolve({
                path: '/homes/serveDetails',
            })
            let serveData = {
                id: item.id,
                label: item.label,
            }
            sessionStorage.setItem('serveData', JSON.stringify(serveData))
            window.open(routeData.href, '_blank')
        },
        recordBtn() {
            window.open(' https://beian.miit.gov.cn/', '_blank')
        },
        jump(url) {
            let routeData = this.$router.resolve({ path: url })
            window.open(routeData.href, '_blank')
        },
    },
}
</script>
<style lang="scss" scoped>
.footer {
    min-width: 1200px;
    background: white;

    .footer_goodFaith {
        width: 1200px;
        margin: 0 auto;
        padding: 46px 0px;

        .goodFaith {
            border-bottom: 1px solid #eaeaea;
            padding: 0px 30px;
            padding-bottom: 29px;

            .list {
                img {
                    width: 49px;
                    height: 55px;
                    margin-right: 20px;
                }

                .name {
                    font-size: 24px;
                    color: #2e323f;
                }
            }
        }
    }

    .privacy_agreement {
        .footer_fl {
            width: 750px;
            padding-left: 29px;
            margin-top: 48px;

            .footer_fl_title {
                .title {
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                    margin-right: 95px;
                    margin-bottom: 22px;
                }

                .footer_fl_list {
                    .list {
                        font-size: 14px;
                        color: #333333;
                        line-height: 26px;
                    }
                }
            }
        }

        .footer_content {
            margin-top: 49px;
            margin-left: -29px;

            .footer_logo {
                width: 227px;
                height: 54px;
            }

            .workingTtime {
                font-size: 14px;
                color: #666666;
                margin-top: 20px;
            }

            .contactPhone {
                margin-top: 11px;

                img {
                    width: 34px;
                    height: 34px;
                }

                .phone {
                    font-size: 30px;
                    font-weight: bold;
                    color: #333333;
                    margin-left: 19px;
                }
            }
        }

        .footer_fr {
            margin-top: 41px;
            margin-left: 60px;

            .scan_code {
                img {
                    width: 117px;
                    height: 117px;
                }

                .scan_code_phone_buy {
                    font-size: 18px;
                    color: #666666;
                    margin-top: 7px;
                }
            }
        }
    }

    .footer_for_record {
        min-width: 1200px;
        height: 265px;
        background: #2e323f;
        padding-top: 61px;

        .footer_for_record_main {
            width: 1200px;
            margin: 0 auto;

            .classification {
                .classification_list {
                    color: #d0d0d0;
                    font-size: 14px;

                    .line {
                        width: 1px;
                        height: 20px;
                        background: #d0d0d0;
                        margin: 0px 8px;
                    }
                }
            }

            .rights_reserved {
                color: #d0d0d0;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    .blogroll {
        height: 31px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #aaaaaa;
        line-height: 18px;
        padding: 0px 80px;
        box-sizing: border-box;
        margin-top: 5px;

        .blogroll_list {
            margin-right: 5px;
        }
    }

    .copyright_img {
        margin-top: 21px;

        .list {
            img {
                width: 109px;
                height: 40px;
            }
        }
    }
}
</style>