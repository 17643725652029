
<template>
    <div class="home">
        <div class="home_head Between">
            <div class="head_fl flex-center">
                您好！欢迎光临筑手建筑防护超市
                <!-- <div class="location_box flex-center pointer">
                <img class="icon_location " src="../assets/img/location.png" alt="">
                广东
                                                            </div> -->
            </div>
            <div class="head_fr">
                <ul class="flex-center">
                    <li class="list tab_list flex-center name_red">
                        <div class="tab_name pointer name_red" @click="toUserCenter" v-if="$store.state.userData.phone">
                            {{
                                                        $store.state.userData.nickname != null
                                                        ? $store.state.userData.nickname
                                                        : $store.state.userData.phone
                                                        }}
                        </div>
                        <div class="tab_name pointer name_red" v-else @click="tabBtn('登录')">
                            登录
                        </div>
                        <div class="line"></div>
                    </li>
                    <li class="list tab_list flex-center name_red">
                        <div class="tab_name pointer name_red" v-if="$store.state.userData.phone" @click="tabBtn('退出登录')">
                            退出登录
                        </div>
                        <div class="tab_name pointer name_red" v-else @click="tabBtn('免费注册')">
                            免费注册
                        </div>
                        <div class="line"></div>
                    </li>
                    <div class="line"></div>
                    <li class="tab_list flex-center" v-for="(item, index) in headTab" :key="index"
                        @click="toDetails(item.name)">
                        <img v-if="item.id == 5" class="icon_phone" src="../assets/img/phone.png" alt="" />
                        <div class="tab_name pointer">
                            {{ item.name }}
                        </div>

                        <div class="line"></div>
                    </li>

                    <li class="name_red head_fr_phone flex-center">
                        <img class="icon_phones" src="../assets/img/phone1.png" alt="" />
                        400-698-6665
                    </li>
                </ul>
            </div>
        </div>
        <div class="logo_search_box flex-center">
            <div class="logo_search flex">
                <div>
                    <img @click="toHome" class="logo pointer" src="../assets/img/logo.png" alt="" />
                </div>
                <div class="logo_search_fr">
                    <div class="search_fr_top flex-center">
                        <div class="search_box Between">
                            <input type="text" @focus="getHomeFocus" :placeholder="placeholderName" v-model="searchValue" />
                            <div class="search_btn display pointer" @click="searcBtn()">
                                <img class="icon_search" src="../assets/img/search.png" alt="" />
                                搜索
                            </div>
                        </div>

                        <div class="pointer cartBtn display" @click="toCart" @mouseover="mouseOverCart"
                            @mouseleave="mouseLeaveCart">
                            <div class="cart_img">
                                <img class="icon_cart" src="../assets/img/cart.png" alt="" />
                            </div>
                            我的购物车
                            <div class="icon_cartNum display">
                                {{ Number($store.state.cartNum) }}
                            </div>
                            <div class="cartList" v-if="cartShow == true && $store.state.cartData.lengt != 0">
                                <div class="cartList_title flex-center" v-if="Number($store.state.cartData.length) != 0">
                                    最新加入的商品
                                </div>
                                <div class="cartList_content" v-if="Number($store.state.cartData.length) != 0" :class="
                                                                    Number($store.state.cartData.length) > 4
                                                                        ? 'cartList_contens'
                                                                        : ''
                                                                ">
                                    <div class="list flex" v-for="(item, index) in $store.state.cartData" :key="index">
                                        <img class="goodsImg" :src="item.coverUrl" alt="" />
                                        <div class="goodsName">{{ item.productName }}</div>
                                        <div class="goodsNum_goodsPrice">
                                            <div class="num_price flex-center">
                                                <div class="goodsPrice">
                                                    ¥{{ Number(item.unitPrice).toFixed(2) }}
                                                </div>
                                                <div class="goodsNum">×{{ item.num }}</div>
                                            </div>
                                            <div class="del pointer" @click.stop="delBtn(item, index)">
                                                删除
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="nodata display" v-else>
                                    <img class="cartEmptyImg" src="@/assets/img/cartEmpty.png" alt="" />
                                    购物车中还没有商品，赶紧采购吧！
                                </div>
                                <div class="cartList_footer Between">
                                    <div class="footer_fl">
                                        共{{ Number($store.state.cartData.length) }}件商品
                                    </div>
                                    <div class="footer_fr toCart display pointer" @click="toCart">
                                        去购物车
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="preliminary_search flex-center flex-wrap">
                        <div class="search_list pointer nowrap" v-for="(item, index) in searchData" :key="index"
                            @click="searcBtns(item)">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content_box">
            <router-view></router-view>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '@/components/footer.vue'
export default {
    components: {
        Footer,
    },
    data() {
        return {
            placeholderName: '防水',
            cartShow: false,
            searchValue: '',
            headTab: [
                {
                    id: 3,
                    name: '我的订单',
                },
                // {
                //     id:5,
                //     name:"手机果尔佳"
                // },
                {
                    id: 6,
                    name: '帮助中心',
                },
            ],
            searchData: [],
            navData: [
                {
                    id: 1,
                    name: '火热推荐',
                },
                {
                    id: 2,
                    name: '建筑场景',
                },
                {
                    id: 3,
                    name: '修缮场景',
                },
                {
                    id: 4,
                    name: '设计与施工技术',
                },
                {
                    id: 5,
                    name: '服务与支持',
                },
            ],
        }
    },
    mounted() {
        if (this.$store.state.userData.token) {
            this.init()
        }
        if (this.$route.query.name) {
            this.searchValue = this.$route.query.name
        }
        this.getSearchRecord()
    },
    methods: {
        getHomeFocus() {
            this.$keyBoard(this, 'searcBtn', 13)
        },
        init() {
            let params = {
                page: 1,
                limit: 10,
                token: this.$store.state.userData.token,
            }
            this.api.findAllPageShopCartGoods(params).then((res) => {
                if (res.code == 0) {
                    this.$store.commit('getcartData', res.data)
                    this.$store.commit('getcartNum', res.count)
                }
            })
        },
        //搜索记录
        getSearchRecord() {
            let params = {
                token: this.$store.state.userData.token,
            }
            this.api.findGoodsSysConfig(params).then((res) => {
                if (res.code == 0) {
                    this.searchData = res.data.slice(0, 6)
                }
            })
        },
        searcBtn() {
            if (this.searchValue != '') {
                sessionStorage.removeItem('serveData')
                localStorage.removeItem('gradeData')
                this.$store.commit('getSearcNum', this.searchValue)
                let url = window.location.href
                if (url.indexOf('/mall/goodsList') >= 0) {
                    this.$router.push({
                        path: '/mall/goodsList',
                        query: {
                            name: this.searchValue,
                            id: '',
                            catGrade: 1,
                            goodsGrade: 1,
                            navName: this.searchValue,
                        },
                    })
                } else {
                    let routeData = this.$router.resolve({
                        path: '/mall/goodsList',
                        query: {
                            name: this.searchValue,
                            id: '',
                            catGrade: 1,
                            goodsGrade: 1,
                            navName: this.searchValue,
                        },
                    })
                    window.open(routeData.href, '_blank')
                }
            }
        },
        searcBtns(item) {
            sessionStorage.removeItem('serveData')
            localStorage.removeItem('gradeData')
            this.$store.commit('getSearcNum', item)
            let url = window.location.href
            if (url.indexOf('/mall/goodsList') >= 0) {
                this.searchValue = item
                this.$router.push({
                    path: '/mall/goodsList',
                    query: {
                        name: item,
                        id: '',
                        catGrade: 1,
                        goodsGrade: 1,
                        navName: item,
                    },
                })
            } else {
                if (item) {
                    this.searchValue = item
                }
                let routeData = this.$router.resolve({
                    path: '/mall/goodsList',
                    query: {
                        name: this.searchValue,
                    },
                })
                window.open(routeData.href, '_blank')
            }
        },
        //去首页
        toHome() {
            this.$router.push('/')
        },
        // 去登陆
        tabBtn(name) {
            if (name == '登录') {
                this.$router.push({
                    path: '/login',
                    query: {
                        type: 1,
                    },
                })
            } else if (name == '免费注册') {
                this.$router.push({
                    path: '/login',
                    query: {
                        type: 3,
                    },
                })
            } else if (name == '退出登录') {
                this.$router.push('/login')
                localStorage.removeItem('userData')
                this.$store.commit('getUserData', {})
                localStorage.removeItem('cartData')
                localStorage.removeItem('skuData')
                this.$store.commit('getcartNum', 0)
                this.$store.commit('getcartData', [])
            }
        },
        //去购物车
        toCart() {
            let routeData = this.$router.resolve({ path: '/cart/index' })
            window.open(routeData.href, '_blank')
        },
        //鼠标移入
        mouseOverCart() {
            this.cartShow = true
        },
        //鼠标移出
        mouseLeaveCart() {
            this.cartShow = false
        },
        delBtn(item, index) {
            let params = {
                token: this.$store.state.userData.token,
                arrayIds: item.id,
            }
            let cartData = this.$store.state.cartData

            if (this.$store.state.userData.token) {
                this.api.delShopCartGoods(params).then((res) => {
                    if (res.code == 0) {
                        this.$message.success('删除成功！')
                        cartData.splice(index, 1)
                        this.$store.commit('getcartData', cartData)
                        this.$store.commit('getcartNum', this.$store.state.cartNum - 1)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                cartData.splice(index, 1)
                this.$store.commit('getcartData', cartData)
                this.$store.commit('getcartNum', cartData.length)
            }
        },
        // 去用户中心
        toUserCenter() {
            if (this.$route.path != '/person/index') {
                let routeData = this.$router.resolve({
                    path: '/person/index',
                })
                window.open(routeData.href, '_top')
            } else {
                //当前已在用户中心页面-再次点击-显示userCenter组件
                sessionStorage.removeItem('broadside')
                this.$store.commit('getBroadside', '')
            }
        },
        toDetails(name) {
            if (name == '我的订单') {
                sessionStorage.removeItem('broadside')
                let data = {
                    isShow: '99',
                }
                this.$store.commit('getBroadside', data)
                if (this.$store.state.userData.token == '') {
                    this.$router.push('/login')
                } else {
                    let routeData = this.$router.resolve({
                        path: '/person/index',
                        query: {
                            type: '',
                        },
                    })
                    window.open(routeData.href, '_blank')
                }
            } else if (name == '帮助中心') {
                sessionStorage.removeItem('serveData')
                let routeData = this.$router.resolve({
                    path: '/homes/serveDetails',
                })
                window.open(routeData.href, '_blank')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.home {
    // height: 100%;

    .home_head {
        width: 1200px;
        height: 34px;
        margin: 0 auto;
        font-size: 12px;
        font-weight: 400;
        color: #353535;
        box-sizing: border-box;

        .head_fl {
            .location_box {
                margin-left: 14px;

                .icon_location {
                    width: 10px;

                    margin-right: 5px;
                }
            }
        }

        .head_fr {
            .tab_list {
                .line {
                    width: 1px;
                    height: 10px;
                    background: #000000;
                    opacity: 0.3;
                    margin: 0px 10px;
                }
            }

            .name_red {
                color: #e62129;
            }

            .head_fr_phone {
                font-weight: bold;

                .icon_phones {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }

            .icon_phone {
                width: 12px;
                height: 18px;
                margin-right: 4px;
            }
        }
    }

    .logo_search_box {
        min-width: 1200px;
        background: white;

        .logo_search {
            width: 1200px;
            height: 134px;
            margin: 0 auto;
            justify-content: space-between;

            .logo {
                width: 360px;
                height: 100px;
                margin-top: 14px;
            }

            .logo_search_fr {
                margin-top: 42px;

                .search_fr_top {
                    justify-self: flex-end;
                }

                .search_box {
                    height: 40px;
                    box-sizing: border-box;
                    background: #ffffff;
                    border: 2px solid #e62129;
                    border-radius: 3px;

                    input {
                        width: 488px;
                        height: 95%;
                        padding-left: 12px;
                        box-sizing: border-box;
                    }

                    .search_btn {
                        width: 110px;
                        height: 40px;
                        background: #e62129;
                        font-size: 16px;
                        color: #ffffff;

                        .icon_search {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .cartBtn {
                    width: 160px;
                    height: 40px;
                    border: 1px solid #dedede;
                    border-radius: 5px;
                    font-size: 16px;
                    color: #e62129;
                    margin-left: 20px;
                    position: relative;

                    .cart_img {
                        .icon_cart {
                            width: 18px;
                            height: 18px;
                            margin-left: -20px;
                            margin-right: 4px;
                        }
                    }

                    .icon_cartNum {
                        width: 26px;
                        height: 16px;
                        background: url('../assets/img/cartBg.png') no-repeat;
                        background-size: 100% 100%;
                        font-size: 14px;
                        color: #ffffff;
                        position: absolute;
                        top: 4px;
                        right: 10px;
                    }
                }
            }
        }

        .preliminary_search {
            margin-top: 6px;
            padding-left: 4px;

            .search_list {
                font-size: 12px;
                color: #666666;
                margin-right: 10px;
            }
        }
    }

    .cartList {
        width: 320px;
        // height: 374px;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 38px;
        left: -162px;
        background: white;
        z-index: 99;
        box-sizing: border-box;

        .cartList_title {
            width: 100%;
            height: 24px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #666666;
            padding-left: 18px;
            box-sizing: border-box;
        }

        .cartList_content::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 0px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 4px;
        }

        .cartList_content::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #d0d0d0;
        }

        .cartList_content::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: white;
        }

        .cartList_contens {
            height: 320px;
            overflow: hidden;
            overflow-y: scroll;
        }

        .cartList_content {
            margin-bottom: 40px;

            .list {
                width: 100%;
                height: 72px;
                padding: 12px 10px 10px;
                border-bottom: 1px solid #e8e8e8;
                box-sizing: border-box;

                .goodsImg {
                    width: 50px;
                    height: 50px;
                }

                .goodsName {
                    width: 167px;
                    height: 42px;
                    font-size: 12px;
                    color: #666666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    margin-left: 9px;
                    line-height: 14px;
                    margin-top: 5px;
                }

                .goodsNum_goodsPrice {
                    margin-left: 14px;
                    margin-top: 4px;

                    .num_price {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666;

                        .goodsPrice {
                            color: #e1251b;
                        }

                        .goodsNum {}
                    }

                    .del {
                        font-size: 12px;
                        color: #333333;
                        margin-left: 25px;
                        margin-top: 4px;
                    }
                }
            }
        }

        .nodata {
            font-size: 14px;
            height: 82px;
            margin-bottom: 40px;
            color: #666666;

            .cartEmptyImg {
                width: 59px;
                height: 49px;
                margin-right: 5px;
            }
        }

        .cartList_footer {
            width: 320px;
            height: 38px;
            background: #f0f0f0;
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0px 20px 0px 11px;

            .footer_fl {
                font-size: 12px;
                color: #666666;
            }

            .footer_fr {
                width: 70px;
                height: 26px;
                background: #e1251b;
                border-radius: 3px;
                font-size: 12px;
                color: #ffffff;
            }
        }
    }
}
</style>